.explore-section {
  padding-top: 5px;
}

.explore-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.posts-grid {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  gap: 5px;
  padding-bottom: 15px;
  flex-wrap: wrap;
  justify-items: center;
}
.tiktok-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, max-content));
  grid-gap: 5px;
  padding: initial;
  justify-content: center;
}

@media screen and (max-width: 400px) { .tiktok-grid { grid-template-columns: repeat(auto-fill, minmax(150px, max-content)); } }
@media screen and (min-width: 600px) { .tiktok-grid { grid-template-columns: repeat(auto-fill, minmax(230px, max-content)); } }

.place-cover {
  height: 310px;
  border-radius: 6px;
  position: relative;
  z-index: 2;
  width: 98%;
  cursor: pointer;
  margin-bottom: 5px;
}

.place-card-title {
  position: absolute;
  left: 16px;
  bottom: 30px;
  font-size: 18px;
  color: var(--primary-bg);
  display: flex;
  gap: 5px;
}
.place-card-subtitle {
  display: flex;
  color: var(--primary-bg);
  position: absolute;
  left: 18px;
  bottom: 6px;
  font-size: 14px;
  gap: 6px;
}

.place-distance {
  margin-right: 5px;
}
.place-faraway {
  color: red;
}

.icon-distance {
  font-size: 14px;
}
.place-search-box {
  display: flex;
  margin-bottom: 16px;
  margin-top: 5px;
  justify-content: center;
}
.taxi-icon {
  padding-top: 1px;

}

.inactive-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background: var(--bg-gradient); */
  background-color: rgba(255,255,255,0.5);
  top: 0;
  left: 0;
  border-radius: 6px;
}

.daycomming {
  color: green;
}