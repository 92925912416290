.top-brands {
  padding-top: 30px;
}

.top-brands-cover {
  height: 150px;
  width: 150px;
  border-radius: 10%;
  background-color: var(--primary-bg);
  box-shadow: var(--collection-shadow);
  border: 0.5px solid var(--border-white);
}

.top-brands-image {
  border-radius: inherit;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
