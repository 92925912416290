.collection-wrapper {
  background-color: var(--secondary-bg);
}

.collection {
  padding: 15px 0px 40px;
}

.collection-subtitle-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.collection-subtitle-text {
  color: var(--subtitle-text);
  font-size: 18px;
}

.collection-location {
  color: var(--zomato-red);
  display: flex;
}

.collection-cover {
  height: 320px;
  width: 255px;
  margin-right: 20px;
  margin-bottom: 15px;
  border-radius: 6px;
  position: relative;
  z-index: 2;
}

.gradient-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--bg-gradient);
  top: 0;
  left: 0;
  border-radius: 6px;
}
.collection-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.collection-card-title {
  position: absolute;
  left: 16px;
  bottom: 30px;
  font-size: 18px;
  color: var(--primary-bg);
}

.collection-card-subtitle {
  display: flex;
  color: var(--primary-bg);
  position: absolute;
  left: 18px;
  bottom: 10px;
  font-size: 14px;
}
