.explore-card {
  margin: 12px 0px 24px 0px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid transparent;
}
.explore-card-first {
  margin-top: 12px;
}
.explore-card-cover {
  height: 248px;
  width: 328px;
  border-radius: 15px;
  position: relative;
}

.explore-card-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.delivery-time {
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0.8;
  background-color: var(--primary-bg);
  backdrop-filter: blur(4px);
  font-weight: 600;
  font-size: 12px;
  padding: 2px 5px 3px;
  border-radius: 4px;
}

.pro-off {
  position: absolute;
  left: 0px;
  bottom: 40px;
  background-color: var(--tag-red);
  color: var(--primary-bg);
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  font-weight: 500;
}

.discount {
  position: absolute;
  background-color: var(--tag-blue);
  color: var(--primary-bg);
  font-weight: 500;
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  left: 0px;
  bottom: 14px;
}
.gold-off {
  position: absolute;
  background-color: var(--tag-red);
  color: var(--primary-bg);
  font-weight: 500;
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  left: 0px;
  bottom: 14px;
}
.res-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0px 6px;
}

.res-name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 600;
  color: var(--heading-text);
}

.res-rating {
  padding: 0px 8px;
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-bg);
  background-color: var(--tag-green);
  border-radius: 6px;
  height: 24px;
}

.res-rating i {
  font-size: 10px;
  margin-left: 2px;
}

.res-cuisine {
  max-width: 56%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.res-cuisine-tag {
  margin-right: 4px;
  color: var(--inactive-text);
}

.res-price {
  font-size: 14px;
  color: var(--inactive-text);
}

.explore-bottom {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.res-bottom-text {
  font-size: 12px;
  color: var(--inactive-text);
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
}

.card-separator {
  height: 1px;
  margin: 10px 0px 5px;
  background-color: var(--border-white);
}

.explore-card:hover {
  background-color: var(--primary-bg);
  border: 1px solid var(--border-white);
}
